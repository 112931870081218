import React from 'react';
import styled from 'styled-components';

const isDev = process.env.NODE_ENV === 'development';
const primaryColor = isDev ? 'black' : '#f1f1f1';

const Progress = ({ className, currentStep, totalSteps }) => {
  const items = Array.from(Array(totalSteps));

  return (
    <>
      <div className={className} style={{}}>
        {items.map((element, index) => (
          <StyledProgressItem key={index} isActive={index + 1 <= currentStep} />
        ))}
      </div>
    </>
  );
};

export default styled(Progress)`
  display: flex;
  max-width: 700px;
  width: 100%;
  margin: 2em auto;
`;

const ProgressItem = ({ className, isActive }) => {
  return <div className={className} />;
};

const StyledProgressItem = styled(ProgressItem)`
  flex: 1;
  height: 10px;
  margin-left: 1px;
  margin-right: 1px;
  background-color: ${(props) => (props.isActive ? primaryColor : 'transparent')};
  border: 1px solid ${primaryColor};
`;
