import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';

const Question = ({ className, isActive, question, children, helpMessage, ...props }) => {
  const duration = 300;

  return (
    isActive && (
      <CSSTransition classNames="question" timeout={duration} {...props}>
        <div className={className}>
          <div style={{ marginBottom: '1em' }}>{question}</div>
          <div>{children}</div>
          {helpMessage && (
            <div style={{ fontSize: '.8em', margin: '1em auto 0', textAlign: 'center' }}>{helpMessage}</div>
          )}
        </div>
      </CSSTransition>
    )
  );
};

Question.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired,
  helpMessage: PropTypes.node,
  isActive: PropTypes.bool,
  question: PropTypes.string.isRequired,
};

Question.defaultProps = {
  helpMessage: null,
  isActive: false,
};

const StyledQuestion = styled(Question)`
  font-size: 1.8em;
  text-align: center;
  padding: 1.8rem;
`;

export default StyledQuestion;
