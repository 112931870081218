import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import chroma from 'chroma-js';
import SelectComponent from 'react-select';

const Select = ({ options, primaryColor, secondaryColor, value, ...props }) => {
  const styles = {
    container: (provided) => ({
      ...provided,
      width: 500,
      maxWidth: '100%',
      margin: '0 auto',
    }),
    control: (provided, state) => ({
      ...provided,
      fontSize: '1em',
      border: `2px solid ${primaryColor}`,
      color: primaryColor,
      backgroundColor: 'transparent',
      ':hover': {
        border: `2px solid ${primaryColor}`,
      },
      ':focus': {
        outline: 'none',
      },
      borderRadius: 0,
      outline: 'none',
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: primaryColor,
      ':hover': {
        color: primaryColor,
      },
      ':active': {
        color: primaryColor,
      },
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      backgroundColor: primaryColor,
    }),
    option: (provided, state) => {
      const color = chroma(secondaryColor);

      return {
        ...provided,
        color: 'black',
        backgroundColor: state.isDisabled
          ? null
          : state.isSelected
          ? secondaryColor
          : state.isFocused
          ? color.alpha(0.3).css()
          : null,
        ':active': {
          ...styles[':active'],
          backgroundColor: !state.isDisabled && (state.isSelected ? secondaryColor : color.alpha(0.6).css()),
        },
      };
    },
    placeholder: (provided, state) => ({
      ...provided,
      color: primaryColor,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: primaryColor,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      paddingTop: '1rem',
      paddingBottom: '1rem',
      ':focus': {
        outline: 'none',
      },
    }),
  };

  const mappedOptions = useMemo(() => options.map(({ name, slug }) => ({ value: slug, label: name })), [options]);
  const selectedValue = useMemo(() => mappedOptions.find((option) => option?.value === value), [mappedOptions, value]);

  return (
    <SelectComponent isSearchable={false} options={mappedOptions} styles={styles} value={selectedValue} {...props} />
  );
};

Select.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  primaryColor: PropTypes.string.isRequired,
  secondaryColor: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default Select;
