import styled from 'styled-components';
import PropTypes from 'prop-types';

const Button = styled('button')`
  position: relative;
  display: inline-block;
  width: auto;
  height: 39px;
  line-height: 39px;
  border: 2px solid ${(props) => (props.type === 'secondary' ? 'transparent' : props.primaryColor)};
  font-size: 13px;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  white-space: nowrap;
  outline: 0;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-shadow: none;
  background-color: transparent;
  box-sizing: initial !important;
  color: ${(props) => props.primaryColor};
  border-radius: 0px;
  padding-left: 15px;
  padding-right: 15px;
  font-family: 'gotham-bold', Arial !important;
  margin-bottom: 1rem;

  & svg {
    margin-${(props) => (props.iconSide === 'left' ? 'right' : 'left')}: 0.5em;
  }

  &:last-child: {
    margin-bottom: 0;
  }
`;

Button.propTypes = {
  iconSide: PropTypes.oneOf(['left', 'right']),
  type: PropTypes.oneOf(['primary', 'secondary']),
};

Button.defaultProps = {
  iconSide: 'left',
  type: 'primary',
};

export default Button;
