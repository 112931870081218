import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const DateInput = React.forwardRef(({ className, value, onClick, primaryColor }, ref) => {
  return <input ref={ref} className={className} onClick={onClick} readOnly value={value} />;
});

DateInput.propTypes = {
  className: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  primaryColor: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default styled(DateInput)`
  font-size: 1em;
  padding: 1rem 2rem;
  border: 2px solid ${(props) => props.primaryColor};
  color: ${(props) => props.primaryColor};
  background-color: transparent;

  &:focus {
    outline: none;
  }
`;
