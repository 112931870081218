import queryString from 'query-string';
import './App.css';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import format from 'date-fns/format';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { TransitionGroup } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faRedo } from '@fortawesome/free-solid-svg-icons';

import Button from './components/Button';
import DateInput from './components/DateInput';
import Question from './components/Question';
import SelectComponent from './components/Select';
import Progress from './components/Progress';

const isDev = process.env.NODE_ENV === 'development';
const TEST_RESULTS = isDev ? true : false;

const formatDate = (date) => format(date, 'yyyy-MM-dd');

function App({
  budgets,
  eventTypes,
  foodTypes,
  groupSizes,
  locations,
  primaryColor,
  provinces,
  resultsPage,
  secondaryColor,
}) {
  const [province, setProvince] = useState('');
  const [date, setDate] = useState(formatDate(new Date()));
  const [foodType, setFoodType] = useState('');
  const [groupSize, setGroupSize] = useState('');
  const [eventType, setEventType] = useState('');
  const [location, setLocation] = useState('');
  const [budget, setBudget] = useState('');
  const [step, setStep] = useState(1);

  const Select = React.memo((props) => (
    <SelectComponent primaryColor={primaryColor} secondaryColor={secondaryColor} {...props} />
  ));

  const provincesSorted = useMemo(
    () => [...(provinces || [])].sort((a, b) => a.name.localeCompare(b.name)).reverse(),
    [provinces]
  );

  const budgetsSorted = useMemo(
    () => [...(budgets || [])].sort((a, b) => parseInt(a.slug || 0) - parseInt(b.slug || 0)),
    [budgets]
  );

  const groupSizesSorted = useMemo(
    () => [...(groupSizes || [])].sort((a, b) => parseInt(a.slug || 0) - parseInt(b.slug || 0)),
    [groupSizes]
  );

  const goToResults = useCallback(() => {
    const params = queryString.stringify({ province, groupSize, eventType, location, foodType, budget, date });

    window.location.replace(`${window.location.origin}${resultsPage}/?${params}`);
  }, [province, groupSize, eventType, location, foodType, budget, date, resultsPage]);

  const nextQuestion = () => {
    setStep((current) => current + 1);
  };

  const nextQuestionConditional = (newValue) => {
    if (newValue) {
      nextQuestion();
    }
  };

  const hasResults = useMemo(
    () => !!(province && groupSize && eventType && location && foodType && budget && date),
    [province, groupSize, eventType, location, foodType, budget, date]
  );

  useEffect(() => {
    if (hasResults) {
      goToResults();
    }
  }, [hasResults, goToResults]);

  const handleClick = () => {
    goToResults();
  };

  const handleGoBack = () => {
    setStep((current) => current - 1 || 1);
  };

  const handleStartOver = () => {
    setBudget('');
    setDate(formatDate(new Date()));
    setEventType('');
    setFoodType('');
    setGroupSize('');
    setLocation('');
    setProvince('');
    setStep(1);
  };

  const handleDate = (newDate) => {
    setDate(formatDate(newDate));

    nextQuestionConditional(newDate);
  };

  const handleProvince = ({ value }) => {
    setProvince(value);

    nextQuestionConditional(value);
  };

  const handleGroupSize = ({ value }) => {
    setGroupSize(value);

    nextQuestionConditional(value);
  };

  const handleEventType = ({ value }) => {
    setEventType(value);

    nextQuestionConditional(value);
  };

  const handleLocation = ({ value }) => {
    setLocation(value);

    nextQuestionConditional(value);
  };

  const handleFoodType = ({ value }) => {
    setFoodType(value);

    nextQuestionConditional(value);
  };

  const handleBudget = ({ value }) => {
    setBudget(value);
  };

  return (
    <div className="mhg-quiz" style={{ color: primaryColor }}>
      <TransitionGroup component={null}>
        <Question isActive={step === 1} question="What province are you in?">
          <Select
            isSearchable={false}
            onChange={handleProvince}
            options={provincesSorted}
            placeholder="Choose a province..."
            value={province}
          />
        </Question>
        <Question
          helpMessage="*Dates subject to availability."
          isActive={step === 2}
          question="What is the date of your event?"
        >
          <DatePicker
            customInput={<DateInput primaryColor={primaryColor} />}
            selected={new Date(`${date}T00:00:00`)}
            minDate={new Date()}
            monthsShown={2}
            onChange={handleDate}
          />
        </Question>
        <Question isActive={step === 3} question="Where at?">
          <Select onChange={handleLocation} options={locations} placeholder="Choose a location..." value={location} />
        </Question>
        <Question isActive={step === 4} question="What type of food?">
          <Select onChange={handleFoodType} options={foodTypes} placeholder="Choose a food type..." value={foodType} />
        </Question>
        <Question isActive={step === 5} question="How many people?">
          <Select
            onChange={handleGroupSize}
            options={groupSizesSorted}
            placeholder="Choose a group size..."
            value={groupSize}
          />
        </Question>
        <Question isActive={step === 6} question="What type of event?">
          <Select
            onChange={handleEventType}
            options={eventTypes}
            placeholder="Choose an event type..."
            value={eventType}
          />
        </Question>
        <Question isActive={step === 7} question="What is your budget?">
          <Select onChange={handleBudget} options={budgetsSorted} placeholder="Choose a budget..." value={budget} />
        </Question>
        <div className="mhg-quiz__buttons-wrap">
          <div className="mhg-quiz__buttons">
            {step > 1 && (
              <>
                <Button
                  className="mhg-quiz__button"
                  disabled={hasResults}
                  onClick={handleGoBack}
                  primaryColor={primaryColor}
                >
                  <FontAwesomeIcon icon={faArrowLeft} />
                  Go back
                </Button>
                <Button
                  className="mhg-quiz__button"
                  disabled={hasResults}
                  iconSide="right"
                  onClick={handleStartOver}
                  primaryColor={primaryColor}
                  type="secondary"
                >
                  Start Over
                  <FontAwesomeIcon icon={faRedo} />
                </Button>
              </>
            )}
            {TEST_RESULTS && (
              <Button onClick={handleClick} primaryColor={primaryColor}>
                click to redirect
              </Button>
            )}
          </div>
        </div>
        <Progress currentStep={step} totalSteps={7} />
      </TransitionGroup>
    </div>
  );
}

App.propTypes = {
  budgets: PropTypes.arrayOf(PropTypes.shape()),
  eventTypes: PropTypes.arrayOf(PropTypes.shape()),
  foodTypes: PropTypes.arrayOf(PropTypes.shape()),
  groupSizes: PropTypes.arrayOf(PropTypes.shape()),
  locations: PropTypes.arrayOf(PropTypes.shape()),
  primaryColor: PropTypes.string,
  provinces: PropTypes.arrayOf(PropTypes.shape()),
  resultsPage: PropTypes.string,
  secondaryColor: PropTypes.string,
};

App.defaultProps = {
  budgets: isDev ? [{ name: '$10 - $20 a person', slug: '10-20_person' }] : [],
  eventTypes: isDev ? [{ name: 'Breakfast', slug: 'breakfast' }] : [],
  foodTypes: isDev ? [{ name: 'An Assortment', slug: 'assortment' }] : [],
  groupSizes: isDev ? [{ name: 'Five-Ten', slug: '5-10' }] : [],
  locations: isDev ? [{ name: 'Private Residence', slug: 'residence' }] : [],
  primaryColor: isDev ? 'black' : '#f1f1f1',
  provinces: isDev
    ? [
        { name: 'New Brunswick', slug: 'nb' },
        { name: 'Prince Edward Island', slug: 'pe' },
        { name: 'Nova Scotia', slug: 'ns' },
      ]
    : [],
  resultsPage: '/results',
  secondaryColor: '#f05a24',
};

export default App;
